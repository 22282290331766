import { push } from 'connected-react-router'
import axios from './Axios';
import i18n from '../i18n';

import {scmCompanyActions} from "./ScmCompany";
import { smeCompanyActions } from "./SmeCompany";
import { platformSettingsActions } from "./PlatformSettings";
import groupBy from "../utils/GroupBy";
import {initializePermissions} from "../utils/HasPermission";
import {loginReusePreventionActions} from "./LoginReusePrevention";

let session = localStorage.getItem('session');
let user = JSON.parse(localStorage.getItem('user'));
let role = localStorage.getItem('role');
let dashboard_type = localStorage.getItem('dashboard_type');
let company_id = JSON.parse(localStorage.getItem('company_id'));
let scm_company_id = JSON.parse(localStorage.getItem('scm_company_id'));
let sme_company_id = JSON.parse(localStorage.getItem('sme_company_id'));
let admin_company_id = JSON.parse(localStorage.getItem('admin_company_id'));
let platform_settings = JSON.parse(localStorage.getItem('platform_settings'));
let roles_permissions = JSON.parse(localStorage.getItem('roles_permissions'));

const initialState = {
    loggedIn: !!session,
    session: session || {},
    user: user || {},
    role: role,
    roles_permissions: roles_permissions,
    dashboardType: dashboard_type,
    company_id: company_id,
    sme_company_id: sme_company_id,
    scm_company_id: scm_company_id,
    admin_company_id: admin_company_id,
    platform_settings: platform_settings || []
};

const authConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    SIGNUP_REQUEST: 'SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'SIGNUP_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
    FORGOT_PASSWORD_CLEAR_MESSAGE: 'FORGOT_PASSWORD_CLEAR_MESSAGE',

    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',

    SET_USER: 'SET_USER',
    REDIRECT_TO_DASHBOARD: 'REDIRECT_TO_DASHBOARD',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',

    ACCEPT_TERMS: 'ACCEPT_TERMS'
};

const clearLocalStorage = (removeAll = false) => {

    localStorage.removeItem('session');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');


    localStorage.removeItem('role');
    localStorage.removeItem('dashboard_type');
    localStorage.removeItem('company_id');
    localStorage.removeItem('scm_company_id');
    localStorage.removeItem('sme_company_id');
    localStorage.removeItem('admin_company_id');
    localStorage.removeItem('platform_settings');
    localStorage.removeItem('roles_permissions');

    const keysToKeep = ['LRP_SESSION'];
    const smePattern = /^sme_\d+_csd_alert_last_seen$/; // Regex pattern to match key 'sme_<user_id>_csd_alert_last_seen'

    Object.keys(localStorage).forEach(key => {
        if (!keysToKeep.includes(key) && !smePattern.test(key)) {
            localStorage.removeItem(key);
        }
    });
    
    if (removeAll) localStorage.clear();
}

export const authActions = {
    /* login */
    loginUser: ({email, password}) => {
        return (dispatch) => {

            // be sure local storage is cleared
            clearLocalStorage();

            dispatch({
                type: authConstants.LOGIN_REQUEST,
                credentials: {email, password},
            });

            return axios.post('/auth/login/', {email, password})
                .then((response) => { dispatch(authActions.loginSuccess(response)); return response; })
                .catch((response) => { dispatch(authActions.loginFailed(response)); return response; });

        };
    },

    loginSuccess: (response, returnUrl) => {
        return (dispatch) => {

            // console.log('response', response);
            const user = response.user;

            let user_companies_permissions = {};

            if (!user.user_permissions || user.user_permissions.length === 0) {
                console.log('user_permissions not set ... can not redirect ...');
                console.warn("User has no dashboard, or permissions, No role defined for the user ...");
                return {}
            }

            localStorage.setItem('session', response.access_token);
            localStorage.setItem('refresh_token', response.refresh_token);
            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('roles_permissions', JSON.stringify(response.roles_permissions));
            if (response?.user?.email)
            loginReusePreventionActions.startSession(response.user.email)(dispatch);

            user_companies_permissions = groupBy(user.user_permissions, 'company_id', )

            let companyIds = Object.keys(user_companies_permissions);
            // this variable should be come from company table
            // we wan't to push user forward as soon as possible
            // map the UserPermission.permission to this
            let dashboardTypes = {
                'super-admin':     'admin',
                'scm-approver':    'scm',
                'scm-submitter':   'scm',
                'scm-admin':       'scm',
                'sme':             'sme',
                'esd':             'esd',
                'esd-specialist':  'esd',
                'esd-client':      'esd',
                'esd-facilitator':'esd',
                'esd-pm':'admin',
                'client-admin':    'admin',
                'funding-specialist': 'funding-specialist',
                'commercial-specialist': 'commercial-specialist',
                'accreditation-specialist': 'accreditation-specialist',
                'contact-centre': 'admin'
            };

            let fns = {
                'admin': null,
                'esd':scmCompanyActions.getScmCompanies,
                'esd-specialist':  scmCompanyActions.getScmCompanies,
                'esd-pm':  scmCompanyActions.getScmCompanies,
                'client-admin': null,
                'scm': scmCompanyActions.getScmCompanies,
                'sme': smeCompanyActions.getSmeCompanies,
                'commercial-specialist':scmCompanyActions.getScmCompanies,
                'contact-centre': null
            };

            let dashboardType = null;

            let payload = {
                session,
                user: user,
                roles_permissions: roles_permissions,
                user_companies_permissions: user_companies_permissions,
                dashboardType: dashboardType
            };

            if ( companyIds.length === 1 ) {
                // user has only one company
                // get that shit and login

                let perm = user_companies_permissions[companyIds[0]];
                let role = perm[0].permission;
                // console.log(user_companies_permissions, 'user_companies_permissions');
                dashboardType = dashboardTypes[role];

                localStorage.setItem('company_id', perm[0].company_id);
                localStorage.setItem('role', perm.map((permission) => permission.permission ));
                localStorage.setItem('dashboard_type', dashboardType);
                const prevPath = sessionStorage.getItem('prevPath')

                let fn = fns[dashboardType]
                if ((role + '').startsWith('esd') || role === 'client-admin' || role === 'funding-specialist') {
                    fn = scmCompanyActions.getScmCompanies;
                }
                let res = fn?
                    fn({ company_id: perm[0].company_id }):
                    (dispatch) => new Promise((resolve) => { resolve({ items: [{id: 0}]})});
                res(dispatch).then((response) => {

                    payload.role = perm.map((permission) => permission.permission)[0]
                    payload.user_company_id = perm[0].company_id
                    payload.company_id = perm[0].company_id
                    payload.dashboardType = dashboardType

                    if ( dashboardType === 'sme' ) {
                        payload['sme_company_id'] = response.items[0]['id'];
                        localStorage.setItem('sme_company_id', response.items[0]['id']);
                    } else if ( dashboardType === 'scm' ) {
                        payload['scm_company_id'] = response.items[0]['id'];
                        localStorage.setItem('scm_company_id', response.items[0]['id']);
                    } else if ((role + '').startsWith('esd') || role === 'client-admin' || role === 'funding-specialist' || role === 'commercial-specialist') {
                        payload['scm_company_id'] = response.items[0]['id'];
                        localStorage.setItem('scm_company_id', response.items[0]['id']);
                    } else if ( dashboardType === 'admin' ) {
                        payload['admin_company_id'] = response.items[0]['id'];
                        localStorage.setItem('admin_company_id', response.items[0]['id']);
                    }



                    if ( response ) {

                        // TODO: clean up this callback hell
                        platformSettingsActions.getPlatformSettings({ per_page: 1000 })(dispatch)
                            .then((response) => {
                                localStorage.setItem('platform_settings', JSON.stringify(response.items));
                                initializePermissions(true);

                                payload['platform_settings'] = response.items;
                                setTimeout(() => {
                                    const parsedPath = JSON.parse(prevPath);
                                    if ( parsedPath?.pathname &&  dashboardType === parsedPath.dashboardType ){
                                        dispatch(push(`${parsedPath?.pathname}${parsedPath?.search ? parsedPath?.search : '' }`))
                                    } else  {
                                        if ( dashboardType === 'admin' ) {
                                            dispatch(push('/admin/dashboard/'))
                                        } else if ( dashboardType === 'scm' ) {
                                            dispatch(push('/scm/dashboard/'))
                                        }else if ( dashboardType === 'esd' ) {
                                            dispatch(push('/esd/dashboard/'))
                                        }else if ( dashboardType === 'sme' ) {
                                            if (user.accepted_policy && user.accepted_terms)
                                                dispatch(push(`/sme/details`));
                                            else
                                                dispatch(push(`/sme/welcome`));
                                        } else if ( dashboardType === 'funding-specialist' ) {
                                            dispatch(push('/funding-specialist/dashboard/'))
                                        } else if ( dashboardType === 'commercial-specialist' ) {
                                            dispatch(push('/commercial-specialist/dashboard/'))
                                        } else if ( dashboardType === 'accreditation-specialist' ) {
                                            dispatch(push('/accreditation-specialist/dashboard/'))
                                        } else {
                                            console.warn("User has no dashboard, or permissions, No role defined for the user ...");
                                        }
                                    }

                                }, 300);

                                dispatch({
                                    type: authConstants.LOGIN_SUCCESS,
                                    payload: payload
                                });
                                sessionStorage.clear()

                            })
                    }
                })

            } else {
                platformSettingsActions.getPlatformSettings({per_page: 1000})(dispatch)
                    .then((response) => {
                        localStorage.setItem('platform_settings', JSON.stringify(response.items));
                        initializePermissions(true);
                        payload['platform_settings'] = response.items;
                        setTimeout(() => {
                            dispatch(push('/choose-company'))
                        }, 300);

                        dispatch({
                            type: authConstants.LOGIN_SUCCESS,
                            payload: payload
                        });
                    })
            }
        };
    },

    loginFailed: (response, returnUrl) => {
        return dispatch => {

            localStorage.removeItem('session');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user');

            if (returnUrl)
                dispatch(push(returnUrl));

            dispatch({
                type: authConstants.LOGIN_FAILURE,
                payload: {error: response || i18n.t('Login Failed')}
            });

        };
    },

    /*logout*/
    logoutUser: (removeAll = false) => {
        return (dispatch) => {

            dispatch({
                type: authConstants.LOGOUT_REQUEST,
            });

            return axios.post('/auth/logout/')
                .then(
                    (response) => { dispatch(authActions.logoutUserSuccess()); return response; },
                    (response) => { dispatch(authActions.logoutFailed()); return response; })
                .finally(() => {
                    // clear session
                    dispatch(loginReusePreventionActions.resetSessionState());
                    clearLocalStorage();
                });

        };
    },

    logoutUserSuccess: () => {
        return (dispatch) => {
            dispatch({
                type: authConstants.LOGOUT_SUCCESS
            })

        };
    },

    logoutFailed: (response, returnUrl) => {
        return dispatch => {

            dispatch({
                type: authConstants.LOGIN_FAILURE,
                payload: {error: response || i18n.t('Logout Failed')}
            });

        };
    },

    /*sign up*/
    signUpUser: ({email, password, password_confirm, is_project, ref, successUrl, failedUrl}) => {
        return (dispatch) => {

            axios.post('/auth/sign-up/', {email, password, is_project, password_confirm, ref})
                .then((response) => dispatch(authActions.signUpSuccess(response, successUrl)))
                .catch((response) => dispatch(authActions.signUpFailed(response, failedUrl)));

            return ({
                type: authConstants.SIGNUP_REQUEST,
                successUrl: successUrl,
                failedUrl: failedUrl
            });
        };
    },

    signUpSuccess: (response, returnUrl) => {
        return (dispatch) => {


            setTimeout(() => {
                // wait for 3 seconds and then redirect
                // show the message to user
                if (returnUrl) {
                    dispatch(push(returnUrl));
                } else {
                    // user complected sign up process but doesn't have a token, need to login
                    dispatch(push('/login'));
                }
            }, 3000);

            dispatch({
                type: authConstants.SIGNUP_SUCCESS,
                payload: {
                    response,
                    message: i18n.t("SignUpPage::Registration Completed")
                }
            });

        };
    },

    signUpFailed: (response, returnUrl) => {
        return dispatch => {

            localStorage.removeItem('session');

            if (returnUrl)
                dispatch(push(returnUrl));

            dispatch({
                type: authConstants.SIGNUP_FAILURE,
                payload: {error: response || i18n.t('SignUpPage::Registration Failed')}
            });

        };
    },

    /*forgot password*/
    forgotPassword: ({email}) => {
        return (dispatch) => {
            axios.post('/auth/forgot-password/', {email})
                .then((response) => dispatch(authActions.forgotPasswordSuccess(response)))
                .catch((response) => dispatch(authActions.forgotPasswordFailed(response)));

            dispatch({
                type: authConstants.FORGOT_PASSWORD,
                payload: {email}
            })
        };
    },

    clearForgotPassword: () => {
        return {
            type: authConstants.FORGOT_PASSWORD_CLEAR_MESSAGE
        }
    },

    forgotPasswordSuccess: (response) => {
        return {
            type: authConstants.FORGOT_PASSWORD_SUCCESS,
            payload: {
                response: response,
                forgotPasswordMessage: i18n.t('ForgotPasswordPage::Forgot Password Success')
            }
        }
    },

    forgotPasswordFailed: (response) => {
        return {
            type: authConstants.FORGOT_PASSWORD_FAILURE,
            payload: {response}
        }
    },

    /*reset password*/
    resetPassword: ({ref, password, password_confirm}) => {
        return (dispatch) => {
            axios.post('/auth/reset-password/', {ref, password, password_confirm})
                .then((response) => dispatch(authActions.resetPasswordSuccess(response)))
                .catch((response) => dispatch(authActions.resetPasswordFailed(response)));

            return {
                type: authConstants.RESET_PASSWORD,
                payload: {password}
            }
        };
    },

    resetPasswordSuccess: (response) => {
        return (dispatch) => {

            setTimeout(() => {
                dispatch(push('/login'));
            }, 3000);

            dispatch({
                type: authConstants.RESET_PASSWORD_SUCCESS,
                payload: {
                    response,
                    message: i18n.t("ResetPasswordPage::Reset Completed")
                }
            });

        };
    },

    resetPasswordFailed: (response) => {
        return {
            type: authConstants.RESET_PASSWORD_FAILURE,
            payload: {response}
        }
    },

    acceptTerms: (response) => {
        return {
            type: authConstants.ACCEPT_TERMS,
            payload: {response}
        }

    },

    setUser: (user) => {
        let newUser = user;
        if (user && user.id) {
            let _user = JSON.parse(localStorage.getItem('user'));
            newUser = JSON.parse(JSON.stringify({..._user, ...user})); // copy new object
            localStorage.setItem('user', JSON.stringify(newUser));
        }
        return {
            type: authConstants.SET_USER,
            payload: {
                user: newUser
            }
        }
    }
};

export function authReducer(state = initialState, action) {
    // console.log('auth reducer', action)
    switch (action.type) {
        /* login */
        case authConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                loggedIn: false,
                credentials: action.credentials
            };
        case authConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggingIn: false,
                loggedIn: true,
                ...action.payload
            };
        case authConstants.LOGIN_FAILURE:
            return {
                ...state,
                loggingIn: false,
                loggedIn: false,
                error: action.payload.error
            };

        /* sign up */
        case authConstants.SIGNUP_REQUEST:
            return {
                ...state,
                error: {},
                signingUp: true,
                signedUp: false,
            };
        case authConstants.SIGNUP_SUCCESS:
            return {
                ...state,
                signingUp: false,
                signedUp: false,
                error: {},
                signupMessage: action.payload.message,
                signupResponse: action.payload.response
            };
        case authConstants.SIGNUP_FAILURE:
            return {
                ...state,
                signingUp: false,
                signedUp: false,
                error: action.payload.error
            };

        /* logout */
        case authConstants.LOGOUT_SUCCESS:
        case authConstants.LOGOUT_REQUEST:
            return {
                loggingIn: false,
                loggedIn: false,
            };

        /* forgot password */
        case authConstants.FORGOT_PASSWORD_CLEAR_MESSAGE:
            return{
                ...state,
                forgotPasswordMessage: ''
            };
        case authConstants.FORGOT_PASSWORD:
            return {
                ...state,
                error: {},
                sendingForgotPassword: true,
                username: action.payload.username
            };
        case authConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                sendingForgotPassword: false,
                forgotPasswordError: false,
                response: action.payload.response,
                forgotPasswordMessage: action.payload.forgotPasswordMessage
            };
        case authConstants.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                sendingForgotPassword: false,
                forgotPasswordError: true,
                error: action.payload.response
            };

        /* reset password */
        case authConstants.RESET_PASSWORD:
            return {
                ...state,
                error: {},
                resettingPassword: true,
                resetPasswordMessage: null
            };
        case authConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resettingPassword: false,
                resetPasswordError: false,
                response: action.payload.response,
                resetPasswordMessage: action.payload.message
            };
        case authConstants.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resettingPassword: false,
                resetPasswordError: true,
                error: action.payload.response,
                resetPasswordMessage: null
            };
        case authConstants.SET_USER:
            return {
                ...state,
                user: action.payload.user
            };
        case authConstants.TOKEN_EXPIRED:
            return {
                ...state,
                tokenExpired: true
            }
        case authConstants.ACCEPT_TERMS:
            let _auth = {
                ...state,
                user: {
                    ...state.user,
                    accepted_terms: true,
                    accepted_policy: true,
                }
            }
            localStorage.setItem('user', JSON.stringify(_auth.user));
            return _auth
        default:
            return state
    }
}
