import axios from 'axios';
import ci from '../ci';


// environment bindings
const __BINDINGS__ = {
    "sasol-uat.onelinkage.link": "sasol-uat.onelinkage.link",
    "sasol-dev.onelinkage.link": "sasol-dev.onelinkage.link",
    "enm-dev.onelinkage.link": "enm-dev.onelinkage.link",
    "enm-uat.onelinkage.link": "enm-uat.onelinkage.link",
    "enm.onelinkage.link": "enm.onelinkage.link",
    "enm-bophelong.onelinkage.link":"enm-bophelong.onelinkage.link",
    "sasol.onelinkage.link": "sasol.onelinkage.link",
    "zevoli-dev.linkage.co.za": "zevoli-dev-api.linkage.co.za",
    "zevoli-staging.linkage.co.za": "zevoli-staging-api.linkage.co.za",
    "vanilla-dev.linkage.co.za": "vanilla-dev-api.linkage.co.za",
    "vanilla-staging.linkage.co.za": "vanilla-staging-api.linkage.co.za",
    // "sasol.linkage.co.za": "sasolapi.linkage.co.za",
    "sasol.linkage.co.za": "sasol.linkage.co.za",
    "localhost": "sasol-dev.onelinkage.link",
    "lh.api7.xyz": "lh.api7.xyz",
    "vanilla-dev.local.localhost": "vanilla-dev-api.linkage.co.za",
    "zevoli-dev.local.localhost": "zevoli-dev-api.linkage.co.za",
    "vanilla-staging.local.localhost": "vanilla-staging-api.linkage.co.za",
    "zevoli-staging.local.localhost": "zevoli-staging-api.linkage.co.za",
    "telkom.linkage.co.za": "telkom-api.linkage.co.za",
};

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const sessionToken = localStorage.getItem('session');
    const refreshToken = localStorage.getItem('refresh_token');
    if (sessionToken){
        config.headers.Authorization = `Bearer ${sessionToken}`
    }
    if (refreshToken) {
        config.headers['X-REFRESH-TOKEN'] = refreshToken
    }
    // the url has protocol or not?
    // if has protocol so the caller knows what is doirefreshng, let it go
    if ( config.url.search("://") > -1 )
        return config;

    if (!ci.testing) {
        let hostname = window.location.hostname;

        let api;

        if ( !__BINDINGS__[hostname] )
            api = "zevoli-dev.linkage.co.za"; // set the default one to development

        if (__BINDINGS__[hostname])
            api = __BINDINGS__[hostname];

        if (process.env.REACT_APP_BASE_OVERRIDE === "true")
            api = process.env.REACT_APP_BASE_URL;
        
        config.url = '//' + api + '/api/v1' + config.url;
    } else
        config.url = '//localhost/api/v1' + config.url;

    return config;
}, function (error) {

    // Do something with request error
    const result = error.response && error.response.data ? error.response.data : error;

    return Promise.reject(result);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data

    // set the new access token, if it's in the response
    if ( response.data.__ACCESS_TOKEN__ ) {
        localStorage.setItem('session', response.data.__ACCESS_TOKEN__)
    }

    // set the new refresh token, if it's in the response
    if ( response.data.refresh_token ) {
        localStorage.setItem('refresh_token', response.data.__REFRESH_TOKEN__)
    }

    if (response && response.data)
        return response.data;

    return response.response && response.response.data ? response.response.data : response;
}, function (error) {

    // Do something with response error
    const result = error.response && error.response.data ? error.response.data : error;

    if (error?.response?.status === 401 && ('' + error?.response?.data['message']).indexOf('expired') > -1 ) {

        // TODO: fix the global variable, couldn't find any better solution
        const { dispatch } = window.store;
        dispatch({ type: 'TOKEN_EXPIRED' });
    }

    return Promise.reject(result);

});

export default axios;

